<template>
  <div class="final-step container">
    <div class="row">
      <div class="col-xl-6">
        <div class="section-header final-step__header">
          <h1 class="section-header__title">{{ pageTitle }}</h1>

          <p class="section-header__text">
            Congratulations! You are done with your
            {{ isResume ? "resume" : "cover letter" }}. Double-check all the
            information you have provided before downloading. If you want to
            make any changes, you may go back to the editor1.
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-5 col-xl-3 order-md-2">
        <button
          v-if="hideDoctype"
          type="button"
          class="download-btn"
          @click.prevent="downloadFile"
        >
          <span class="download-btn__icon">
            <AppIcon componentName="CloudIcon" />
          </span>

          <span class="download-btn__title">Download Resume</span>
        </button>

        <DownloadButton
          v-if="!hideDoctype"
          :loading="isDownloadingResume"
          @download="downloadFile"
          :title="buttonTitle"
        />

        <FinalControls
          class="final-controls"
          @dashboard="goToDashboard"
          @print="printTemplate"
          @mail="openModal"
          @edit="editResume"
        />
      </div>

      <div class="col-md col-xl-9 order-md-1">
        <PdfViewer :src="previewPDF" v-if="showPDF"> </PdfViewer>
        <transition name="fade">
          <div
            v-if="previewPDF == '' || previewPDF == null"
            class="preview-loader"
          >
            <AppButtonLoader :size="100" color="#4874e6" />
          </div>
        </transition>
      </div>
    </div>
    <AppModal :isOpenModal="isOpenModal" width="636px" @close="closeModal">
      <SendViaEmailModal
        :title="isResume ? null : 'Cover Letter'"
        @sendEmail="mailResume"
      />
    </AppModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { downloadingMixin } from "@/mixins/DownloadingMixin";
import { showTextMixin } from "@/mixins/ShowTextMixin";

import {
  SUBSCRIBE_STEPS_NAMES,
  TEMPLATE_EDITING_NAMES,
} from "@/helpers/rout-constants";

import CompletedTemplate from "@/helpers/completed-template-hash";
import DownloadButton from "@/components/ui/DownloadButton";
import AppButtonLoader from "@/components/ui/AppEmbedLoader";
import FinalControls from "@/components/creation/FinalStepControls";
import SendViaEmailModal from "@/components/dashboard/SendViaEmailModal";
import printJS from "print-js";
import AppToast from "@/helpers/toast-methods";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";
import CookieHelper from "@/helpers/cookie-helper";
import PdfViewer from "@/components/PdfViewer";

export default {
  name: "TemplateDetails",
  mixins: [downloadingMixin, showTextMixin, mixpanelMixin],

  components: {
    DownloadButton,
    FinalControls,
    SendViaEmailModal,
    AppButtonLoader,
    PdfViewer,
  },

  computed: {
    isResume() {
      return this.$route.meta.templateType === "resume";
    },

    pageTitle() {
      return this.isResume ? "Resume Details" : "Cover Letter Details";
    },

    preview() {
      return this.isResume ? this.previewResumeAll : this.previewLetter;
    },

    isLoadingPreview() {
      return this.isResume
        ? this.isLoadingPreviewResume
        : this.isLoadingPreviewLetter;
    },

    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      isSubscribed: "user/isSubscribed",
      previewResumeAll: "resumeCreation/getResumePreviewAll",
      previewLetter: "coverLetterCreation/getCoverLetterPreview",
      isLoadingPreviewResume: "resumeCreation/isLoadingPreview",
      isLoadingPreviewLetter: "coverLetterCreation/isLoadingPreview",
      previewCoverLetterPDF: "coverLetterCreation/getCoverLetterPreviewPDF",
      previewResumePDF: "resumeCreation/getResumePreviewPDF",
    }),
  },

  data() {
    return {
      isDownloadingResume: false,
      isOpenModal: false,
      loading: false,
      templateHash: null,
      buttonTitle: "",
      hideDoctype: false,
      previewPDF: "",
      showPDF: false,
    };
  },

  methods: {
    redirectUnsubscribed() {
      if (!this.isSubscribed) {
        this.templateHash = this.$route.params.id;
        if (this.isResume) {
          CompletedTemplate.set({ hash: this.templateHash, type: "resume" });
        } else {
          CompletedTemplate.set({ hash: this.templateHash, type: "letter" });
        }
        this.$router.push({ name: SUBSCRIBE_STEPS_NAMES.SUBSCRIBE_PLANS });
        return false;
      }

      return true;
    },

    async downloadFile(format) {
      if (!this.redirectUnsubscribed()) return;

      this.isDownloadingResume = true;

      this.showTextWillDownload();

      const fileBlob = await this.getFile(
        this.templateHash,
        format,
        this.isResume
      );

      const fileName = await this.getFileName(
        this.templateHash,
        format,
        this.isResume
      );

      this.initialisationDownloadFile(fileBlob, fileName);

      this.isDownloadingResume = false;
    },

    async printTemplate() {
      if (!this.redirectUnsubscribed()) return;

      this.showTextWillPrint();

      const fileBlob = await this.getFile(
        this.templateHash,
        "pdf",
        this.isResume
      );

      printJS(URL.createObjectURL(fileBlob));
    },

    async openModal() {
      if (!this.redirectUnsubscribed()) return;

      this.isOpenModal = true;
    },

    closeModal() {
      this.isOpenModal = false;
    },

    async mailResume(emailData) {
      await this.$store.dispatch("loader/pending");
      try {
        const formData = new FormData();
        formData.append("hash", this.templateHash);
        formData.append("from", emailData.from);
        formData.append("to", emailData.to);
        formData.append("subject", emailData.subject);
        if (emailData.cc_emails) {
          emailData.cc_emails.forEach((email, idx) =>
            formData.append(`cc_emails[${idx}]`, email)
          );
        }
        if (emailData.attachments) {
          emailData.attachments.forEach((file, idx) =>
            formData.append(`attachments[${idx}]`, file)
          );
        }
        formData.append("text", emailData.text);
        if (this.isResume) {
          await this.$store.dispatch(
            "userDashboard/sendResumeByEmail",
            formData
          );
        } else {
          await this.$store.dispatch(
            "userDashboard/sendLetterByEmail",
            formData
          );
        }

        this.closeModal();
      } catch (err) {
        console.error(err);
      }
      await this.$store.dispatch("loader/done");
    },

    editResume() {
      this.$router.push({
        name: this.isResume
          ? TEMPLATE_EDITING_NAMES.RESUME_EDITING
          : TEMPLATE_EDITING_NAMES.COVER_LETTER_EDITING,
        query: { hash: this.templateHash },
        params: {
          duplicate: true,
        },
      });
    },
    goToDashboard() {
      this.$router.push("/dashboard");
    },
  },

  async created() {
    this.$store.commit("auth/setAvailableUncompletedResume", false);

    if (this.isResume) {
      this.buttonTitle = "Download Resume";
    } else {
      this.buttonTitle = "Download Cover Letter";
    }

    if (this.$route?.query?.uncompletedTemplate && CompletedTemplate.get()) {
      await this.$store.dispatch("loader/pending");
      try {
        if (this.isResume) {
          await this.$store.dispatch("resumeCreation/bindResumeToUser");
        } else {
          await this.$store.dispatch("coverLetterCreation/bindLetterToUser");
        }
      } catch (e) {
        console.error(e);
      }
      await this.$store.dispatch("loader/done");
    }

    let flagHideDoctype = CookieHelper.getFlag("hide_doctype", "");

    if (flagHideDoctype == "1") {
      if (this.isSubscribed) {
        this.hideDoctype = false;
      } else {
        this.hideDoctype = true;
      }
    }

    this.templateHash = this.$route.params.id;

    CompletedTemplate.clear();

    if (this.isResume) {
      this.mixpanelTrack("V2-RESUMEDETAILS");
    } else {
      this.mixpanelTrack("V2-LETTERDETAILS");
    }

    try {
      if (this.isResume) {
        await this.$store.dispatch(
          "resumeCreation/getResumePreviewAll",
          this.templateHash
        );

        this.previewPDF = this.previewResumePDF;
      } else {
        await this.$store.dispatch(
          "coverLetterCreation/getCoverLetterPreviewAll",
          this.templateHash
        );

        this.previewPDF = this.previewCoverLetterPDF;
      }
    } catch (err) {
      console.error(err);
    }

    if (this.previewPDF != "") {
      this.showPDF = true;
    }

    //prevent right click
    document.addEventListener("contextmenu", (event) => event.preventDefault());

    await this.$store.dispatch("loader/done");
  },
};
</script>

<style lang="scss" scoped>
::v-deep .section-header {
  &__title {
    font-size: 32px;

    span {
      color: $primary;
    }
  }

  &__text {
    font-size: 14px;
    color: $light-gray;
    margin-top: 8px;
  }
}

.final-step {
  padding-top: 40px;
  padding-bottom: 94px;

  &__header {
    margin-bottom: 40px;
  }
}

.resume-preview {
  padding-top: 1054/816 * 100%;
  background-color: #fff;
  position: relative;
  width: 100%;
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.final-controls {
  margin: 24px 0;
}

.preview-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#fff, 0.8);
  backdrop-filter: blur(7px);
}

.download-btn {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 56px;
  border-radius: 8px;
  background-color: $primary;
  overflow: hidden;
  position: relative;
  padding: 0;

  &__title {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    flex-grow: 1;
    margin-left: -10px;
  }

  &__icon {
    height: 100%;
    width: 55px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: #fff;
      width: 24px;
      height: 24px;
    }

    &--arrow {
      svg {
        width: 13px;
        height: 8px;
      }
    }
  }
  &:active {
    background-color: #416fe5;
  }
}
</style>
